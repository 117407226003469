import type React from 'react';
import { type PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';

import { type Scalars } from '../__generated-gql-types__/globalTypes';

import {
  type LexContextLexFragment,
  useLexContextLexListQuery,
} from './__generated-gql-types__/LexContext.generated';

const ENABLED_COACH_URNS: Array<Scalars['CoachUrn']['output']> = [];

export enum LexSlug {
  // remove example when adding proper lex here
  EXAMPLE = 'example',
}

// list of Lex slugs to evaluate
const LEX_SLUGS_TO_EVALUATE: LexSlug[] = Object.values(LexSlug).filter(
  (slug) => slug !== LexSlug.EXAMPLE,
);

const MISSING_LEX_CONTEXT_PROVIDER =
  'You forgot to wrap your app in <LexProvider>';

interface LexContext {
  isLexEnabled: (lexKey: LexSlug) => boolean;
  isLexEnabledForCoach: (coachUrn: Scalars['CoachUrn']['output']) => boolean;
  isLexLoading: boolean;
}

export const LexContext = createContext<LexContext>({
  get isLexEnabled(): never {
    throw new Error(MISSING_LEX_CONTEXT_PROVIDER);
  },
  get isLexEnabledForCoach(): never {
    throw new Error(MISSING_LEX_CONTEXT_PROVIDER);
  },
  get isLexLoading(): never {
    throw new Error(MISSING_LEX_CONTEXT_PROVIDER);
  },
});

// to prevent potential layout shift, initialize some fully ramped lex slugs by default
// NOTE: should only be temporary until the Lex is removed
const DEFAULT_INIT_LIST: LexContextLexFragment[] = [];

/**
 * LeX is short for Leland Experiment to enable A/B testing.
 * `useLex` to check whether the experiment/feature is ramped to the current user/guest
 */
export const useLex: () => LexContext = () =>
  useContext<LexContext>(LexContext);

const LexContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // TODO: listen to user data change to reload rampedExperiments
  const { data, loading } = useLexContextLexListQuery({
    variables: { slugs: LEX_SLUGS_TO_EVALUATE },
    skip: LEX_SLUGS_TO_EVALUATE.length === 0,
  });

  const value: LexContext = useMemo(() => {
    const lexIsRampedMap = (data?.lexListBySlugs ?? DEFAULT_INIT_LIST).reduce<
      Record<string, boolean>
    >((map, lex) => {
      map[lex.slug] = lex.isRamped;
      return map;
    }, {});

    return {
      isLexEnabled: (slug) => !!lexIsRampedMap[slug],
      isLexEnabledForCoach: (coachUrn) => ENABLED_COACH_URNS.includes(coachUrn),
      isLexLoading: loading,
    };
  }, [data?.lexListBySlugs, loading]);

  return <LexContext.Provider value={value}>{children}</LexContext.Provider>;
};

export default LexContextProvider;
